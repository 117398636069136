import { USER_PROFILE_HEADER } from "@albi-types/base/user";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { USER_ACCES_SELECTORS } from "src/userAccessStore/userAccessStore.selectors";
import { resolvedEnterpriseCompanyAccess } from "src/userAccessStore/userAccessStore.state";
import { ResolvedUserAccessType } from "src/utils/sharedValues";
import { AuthService } from "./auth.service";

export type HttpCallOptions = {
    useCompanyHeader: boolean,
    useEnterpriseCompanyHeader?: boolean,
    extraHeaders?: { key: string, value: string }[],
    specificLocationId?: string
}

@Injectable()
export class BackendService {
    private readonly _baseUrl = environment.apiUrl;
    private selectedUserAccess$ = new BehaviorSubject<ResolvedUserAccessType>(null);
    private selectedEnterpriseCompanyAccess$ = new BehaviorSubject<resolvedEnterpriseCompanyAccess>(null);
    private readonly _defaultOptsValue = {
        useCompanyHeader: true,
        useEnterpriseCompanyHeader: true,
    }

    constructor(private _http: HttpClient, private _authService: AuthService, private _store: Store) {
        this._store.select(USER_ACCES_SELECTORS.selectSelectedUserAccesses).pipe(
            tap(value => this.selectedUserAccess$.next(value)),
        ).subscribe();
        this._store.select(USER_ACCES_SELECTORS.selectSelectedEnterpriseAccess).pipe(
            tap(enterpriseAccess => this.selectedEnterpriseCompanyAccess$.next(enterpriseAccess))
        ).subscribe();
    }

    public get<T>(endpoint: string, opts: HttpCallOptions = this._defaultOptsValue): Observable<T> {
        const headers = this.calculateHeaders(opts);
        return this._http.get<T>(`${this._baseUrl}/${endpoint}`, {
            headers: headers,
        });
    }


    public getFullResponse<T>(endpoint: string, opts: HttpCallOptions = this._defaultOptsValue): Observable<HttpResponse<T>> {
        const headers = this.calculateHeaders(opts);

        return this._http.get<T>(`${this._baseUrl}/${endpoint}`, {
            headers: headers,
            observe: 'response',

        });
    }


    public post<T>(endpoint: string, body: any, opts: HttpCallOptions = this._defaultOptsValue): Observable<T> {
        const headers = this.calculateHeaders(opts);

        return this._http.post<T>(`${this._baseUrl}/${endpoint}`, body, {
            headers: headers
        });
    }

    public postFullResponse<T>(endpoint: string, body: any, opts: HttpCallOptions = this._defaultOptsValue): Observable<HttpResponse<T>> {
        const headers = this.calculateHeaders(opts);

        return this._http.post<T>(`${this._baseUrl}/${endpoint}`, body, {
            headers: headers,
            observe: 'response'
        });
    }

    public patch<T>(endpoint: string, etag: string, body: any, opts: HttpCallOptions = this._defaultOptsValue): Observable<T> {
        const headers = this.calculateHeaders(opts, etag);

        return this._http.patch<T>(`${this._baseUrl}/${endpoint}`, body, {
            headers: headers
        });
    }

    public delete<T>(endpoint: string, etag: string, opts: HttpCallOptions = this._defaultOptsValue): Observable<T> {
        const headers = this.calculateHeaders(opts, etag);

        return this._http.delete<T>(`${this._baseUrl}/${endpoint}`, {
            headers: headers
        });
    }

    private calculateHeaders(opts: HttpCallOptions, etag?: string): HttpHeaders | {
        [header: string]: string | string[];
    } {
        // console.log(this.selectedUserAccess$.value);
        const selectedAccess = this.selectedUserAccess$.value;
        const selectedEnterpriseAccess = this.selectedEnterpriseCompanyAccess$.value;

        const headers: HttpHeaders | {
            [header: string]: string | string[];
        } = {
            "Authorization": `Bearer ${this._authService.getSessionInfo().accessToken}`,
        }

        if (selectedAccess) {
            if (selectedAccess?.company?._id && opts.useCompanyHeader) {
                headers[USER_PROFILE_HEADER.company] = selectedAccess.company._id;
            }
            if ((selectedAccess?.location?._id && opts.useCompanyHeader) || opts.specificLocationId) {
                headers[USER_PROFILE_HEADER.location] = selectedAccess.location?._id || opts.specificLocationId;
            }
            if (selectedEnterpriseAccess?.company?._id && opts.useEnterpriseCompanyHeader) {
                headers[USER_PROFILE_HEADER.enterprisecompanyAccess] = selectedEnterpriseAccess.company._id;
            }
            if (selectedEnterpriseAccess?.location?._id && opts.useEnterpriseCompanyHeader) {
                headers[USER_PROFILE_HEADER.enterpriseLocationAccess] = selectedEnterpriseAccess.location._id;
            }
        }
        if (opts.extraHeaders) {
            opts.extraHeaders.forEach(elm => headers[elm.key] = elm.value)
        }


        /* eslint-disable no-fallthrough */
        /*
        switch (true) {
            // @ts-ignore
            case (selectedAccess?.company && opts.useCompanyHeader):
                headers[USER_PROFILE_HEADER.company] = selectedAccess.company._id;
            //falls through
            // @ts-ignore
            case (selectedAccess?.location && opts.useLocationHeader):
                headers[USER_PROFILE_HEADER.location] = selectedAccess.company._id;
            //falls through
            // @ts-ignore
            case (selectedEnterpriseAccess?.company?._id && opts.useEnterpriseCompanyHeader):
                headers[USER_PROFILE_HEADER.enterprisecompanyAccess] = selectedEnterpriseAccess.company._id;

            //falls through
            // @ts-ignore
            case (selectedEnterpriseAccess?.location?._id && opts.useEnterpriseCompanyHeader):
                headers[USER_PROFILE_HEADER.enterpriseLocationAccess] = selectedEnterpriseAccess.location._id;
        }*/
        /* eslint-enable no-fallthrough */
        if (etag) {
            headers["if-match"] = etag;
        }
        return headers
    }
}