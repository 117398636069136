import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SHARED_DICTIONARY } from "src/translations/dictionaries/shared.dictionary";

@Pipe({
    name: 'expiryDaysLeftPipe',
    standalone: false
})
export class ExpiryDaysLeftPipe implements PipeTransform {

    private sharedDictionary = SHARED_DICTIONARY;

    constructor(
        private _translateService: TranslateService,
    ) { }

    transform(value: string | Date, ...args: []) {
        const now = new Date();
        const expirationDate = new Date((typeof value === 'string' ? new Date(value) : value).getTime() + 1000 * 60 * 60 * 24 * 182);
        // Calcolo la differenza in millisecondi
        const timeDifference = expirationDate.getTime() - now.getTime();
        // Calcolo i giorni rimanenti (arrotondati verso il basso)
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        if (daysDifference > 0) {
            return this._translateService.instant(this.sharedDictionary.infoText.expiredInDays, { expireDays: Math.abs(daysDifference) });
        } else {
            return this._translateService.instant(this.sharedDictionary.infoText.expiredSince, { expireDays: Math.abs(daysDifference) });
        }
    }
}