import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export type ItemListType = {
    key: string,
    name: string,
    boolean: {
        booleanValue: boolean,
        booleanKey: string,
    }[]
}

@Component({
    selector: 'albi-list-item',
    templateUrl: 'albi-list-item.component.html',
    styleUrls: ['albi-list-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AlbiListItem),
            multi: true,
        }],
    standalone: false
})
export class AlbiListItem implements AfterViewInit, ControlValueAccessor {

    @Input() booleanText: string[];

    @Output() onCloseClick = new EventEmitter<ItemListType>();
    @Input() leftPointColor: 'orange' | 'gold' | 'rose' | 'purple' | 'info' | 'eco';

    public value: ItemListType;


    private onChange: (value: ItemListType) => void;
    private onTouched: () => void;
    public booleanValue: boolean;

    constructor(private _elementRef: ElementRef) { }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('albi-list-item')
    }

    writeValue(obj: ItemListType): void {
        this.value = obj;
    }
    registerOnChange(fn: (value: ItemListType) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        //Implement if needed
    }

    public onBlur(): void {
        this.onTouched();
    }

    public onValueChange(event: boolean, index: number) {
        const val = this.value;
        val.boolean[index].booleanValue = event;
        this.onChange(val)
    }

    public handleCloseClick() {
        this.onCloseClick.emit(this.value);
    }
}