"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserAccessStatusEnum = exports.USER_PROFILE_HEADER = void 0;
exports.USER_PROFILE_HEADER = {
  company: "albi-current-company",
  location: "albi-current-location",
  coordinates: "albi-user-coordinates",
  enterprisecompanyAccess: "albi-enterprise-company-access",
  enterpriseLocationAccess: "albi-enterprise-location-access"
};
/**
 * Enum for defining the status of a user's access to a company or location.
 *
 * @enum {string}
 * @property {string} ACTIVE - Indicates the user has active access.
 * @property {string} PENDING - Indicates the user's access is pending approval from company admins.
 * @property {string} PENDING_DOCUMENT - Indicates the user's access is pending document verification from albicchiere admins.
 * @property {string} PENDING_EMAIL - Indicates the user's access is pending email verification corresponding to company domain.
 * @property {string} REJECTED - Indicates the user's access has been rejected.
 */
var UserAccessStatusEnum;
(function (UserAccessStatusEnum) {
  UserAccessStatusEnum["ACTIVE"] = "active";
  UserAccessStatusEnum["PENDING"] = "pending";
  UserAccessStatusEnum["PENDING_DOCUMENT"] = "pending_document";
  UserAccessStatusEnum["PENDING_EMAIL"] = "pending_email";
  UserAccessStatusEnum["REJECTED"] = "rejected";
})(UserAccessStatusEnum = exports.UserAccessStatusEnum || (exports.UserAccessStatusEnum = {}));
