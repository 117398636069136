import { IWine } from "@albi-types/wine/wine";
import { IWineGrape, IWineRegion } from "@albi-types/wine/wineSetting";
import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, ViewEncapsulation, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlbiChipType, AlbiOption } from "albi-ui/dist/albi-ui-library";
import { BehaviorSubject, combineLatest, of, take, tap } from "rxjs";
import { BackendService } from "src/services/backend.service";
import { HeaderMessageService } from "src/services/header-message.service";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";
import { SHARED_DICTIONARY } from "src/translations/dictionaries/shared.dictionary";
import { LANGUAGES_OPTIONS } from "src/utils/sharedValues";

export type WineCreateFormType = {
    name: FormControl<string>;
    wineColor: FormControl<AlbiOption>;
    wineTypes: FormControl<string>;
    region: FormControl<IWineRegion>;//edit -> {region._id}
    country: FormControl<{ countryCode: string; countryName: string; }>;//edit -> {country.countryCode}
    grapes: FormControl<AlbiChipType[]>;//edit -> {grape[].key}
}

@Component({
    selector: 'wine-creation-form',
    templateUrl: 'wine-creation-form.component.html',
    styleUrls: ['wine-creation-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WineCreationFormComponent implements OnInit {
    private readonly _destroy: DestroyRef = inject(DestroyRef);

    labelImage$ = new BehaviorSubject<string>(null);
    bottleImage$ = new BehaviorSubject<string>(null);
    switchEditableModeControl: FormControl<boolean>;

    @Input({ required: true }) wineCreateForm: FormGroup<WineCreateFormType>;
    @Input() selectedWineId: string;
    @Input() notEditableMode: boolean = false;

    @Input() switchEditableMode: boolean;

    @Input() headerParagraphText: string;



    wineColorOptions: (AlbiOption & { iconName: string })[];

    wineTypeOptions: { key: string, name: string, description?: string }[];

    languageOptions: AlbiOption[] = LANGUAGES_OPTIONS;

    wineRegionsSetting$ = new BehaviorSubject<IWineRegion[]>(null);
    wineRegionOptions$ = new BehaviorSubject<IWineRegion[]>(null);
    wineCountryOptions$ = new BehaviorSubject<{ countryCode: string; countryName: string; }[]>(null);

    wineGrapesSetting$ = new BehaviorSubject<IWineGrape[]>(null);
    wineGrapesSuggestions$ = new BehaviorSubject<AlbiChipType[]>(null);

    public readonly wineFormDictionary = COMPONENTS_DICTIONARY.wineCreationForm;
    public readonly sharedDictionary = SHARED_DICTIONARY;

    constructor(
        private _backendService: BackendService,
        private _headerMessageService: HeaderMessageService,
        private _store: Store,
        private _router: Router,
        private _translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        if (!this.headerParagraphText) {
            this.headerParagraphText = this._translateService.instant(this.wineFormDictionary.description);
        }
        this.wineColorOptions = [
            {
                key: 'red',
                label: this._translateService.instant(this.sharedDictionary.wine.color.red),
                iconName: 'wine.glassFullRed'
            },
            {
                key: 'white',
                label: this._translateService.instant(this.sharedDictionary.wine.color.white),
                iconName: 'wine.glassFullWhite'
            },
            {
                key: 'rose',
                label: this._translateService.instant(this.sharedDictionary.wine.color.rose),
                iconName: 'wine.glassFullRose'
            },
        ];
        this.wineTypeOptions = [
            {
                key: 'sparkling',
                name: this._translateService.instant(this.sharedDictionary.wine.types.sparkling)
            },
            {
                key: 'fortified',
                name: this._translateService.instant(this.sharedDictionary.wine.types.fortified)
            },
            {
                key: 'sweet',
                name: this._translateService.instant(this.sharedDictionary.wine.types.sweet)
            },
        ];
        this.wineCreateForm.controls.country.disable();

        if (this.switchEditableMode) {
            this.switchEditableModeControl = new FormControl(false);
            this.switchEditableModeControl.valueChanges.pipe(
                tap(value => {
                    Object.keys(this.wineCreateForm.controls).forEach(key => {
                        if (key !== 'country') {
                            value ?
                                this.wineCreateForm.controls[key as keyof WineCreateFormType].enable() :
                                this.wineCreateForm.controls[key as keyof WineCreateFormType].disable()
                        }
                    });
                }),
                takeUntilDestroyed(this._destroy)
            ).subscribe();
            Object.keys(this.wineCreateForm.controls).forEach(key => {
                this.wineCreateForm.controls[key as keyof WineCreateFormType].disable()
            })
        }

        combineLatest([
            this._backendService.get<{ regions: IWineRegion[] }>(`app_settings/wine_setting/regions`, { useCompanyHeader: false }),
            this._backendService.get<{ grapes: IWineGrape[] }>(`app_settings/wine_setting/grapes`, { useCompanyHeader: false }),
            this.selectedWineId ? this._backendService.get<{ wine: IWine }>(`wine/wines/${this.selectedWineId}?raw=true`) : of(null)
        ]).pipe(
            tap(([regionSettingResponse, grapeSettingResponse, wineResponse]) => {
                this.wineRegionsSetting$.next(regionSettingResponse.regions);
                this.wineGrapesSetting$.next(grapeSettingResponse.grapes);
                if (wineResponse) {
                    const grapesArray = grapeSettingResponse.grapes;
                    const regionArray = regionSettingResponse.regions;
                    const countryArray = regionSettingResponse.regions.reduce((acc: { countryCode: string, countryName: string }[], curr) => {
                        if (!acc.map(elm => elm.countryCode).includes(curr.countryCode)) {
                            acc.push({
                                countryCode: curr.countryCode,
                                countryName: curr.country
                            })
                        }
                        return acc
                    }, []);
                    this.wineCreateForm.patchValue({
                        country: countryArray.find(elm => elm.countryCode === wineResponse.wine.country),
                        grapes: wineResponse.wine.grapes.map(responseGrape => {
                            const grape = grapesArray.find(elm => elm._id === responseGrape);
                            return ({
                                text: grape.name,
                                key: grape._id
                            })
                        }),
                        name: wineResponse.wine.name,
                        region: regionArray.find(elm => elm._id === wineResponse.wine.region),
                        wineColor: this.wineColorOptions.find(elm => elm.key === wineResponse.wine.wineColor),
                        wineTypes: wineResponse.wine.wineTypes?.[0],
                    })
                };
            }),
            take(1)
        ).subscribe()
    }


    hasValidatorRequire(control: FormControl) {
        return (control.validator === Validators.required);
    }

    onCountrySelect(ev: { countryCode: string; countryName: string; }) {
        if (this.wineCreateForm.controls.region.getRawValue().countryCode !== ev.countryCode) {
            this.wineCreateForm.controls.region.reset();
        }
    }
    onRegionSelect(ev: IWineRegion) {
        this.wineCreateForm.controls.country.setValue({
            countryCode: ev.countryCode,
            countryName: ev.country
        });
    }

    //COMPLETE METHODS
    completeRegionSuggestions(ev: string) {
        let newSuggestions: IWineRegion[] = null;
        // const selectedCountry = this.wineCreateForm.controls.country.getRawValue();
        if (ev) {
            newSuggestions = this.wineRegionsSetting$.value
                .filter(elm => elm.name.toLowerCase().includes(ev.toLowerCase()))
            // .filter(elm => !(selectedCountry && elm.countryCode !== selectedCountry.countryCode));
            newSuggestions = newSuggestions?.length > 0 ? newSuggestions : null;
        }
        this.wineRegionOptions$.next(newSuggestions);
    }
    completeCountrySuggestions(ev: string) {
        let newSuggestions: { countryCode: string; countryName: string; }[] = null;
        if (ev) {
            const uniqueCountryCodes = new Set<string>();
            const countryOptions: { countryCode: string; countryName: string; }[] = [];
            for (const region of this.wineRegionsSetting$.value) {
                if (!uniqueCountryCodes.has(region.countryCode)) {
                    uniqueCountryCodes.add(region.countryCode);
                    countryOptions.push({ countryCode: region.countryCode, countryName: region.country })
                }
            }
            newSuggestions = countryOptions
                .filter(elm => elm.countryName.toLowerCase().includes(ev.toLowerCase()));
            newSuggestions = newSuggestions?.length > 0 ? newSuggestions : null;
        }
        this.wineCountryOptions$.next(newSuggestions);
    }
    completeGrapeSuggestions(ev: string) {
        if (!ev) {
            this.wineGrapesSuggestions$.next(null);
            return;
        }
        const selectedGrapes = this.wineCreateForm.controls.grapes.value.map(val => val.key);
        console.log(selectedGrapes)
        this.wineGrapesSuggestions$.next(this.wineGrapesSetting$.value
            .filter(elm => !selectedGrapes.includes(elm._id))
            .filter(elm => elm.name.toLowerCase().includes(ev.toLowerCase()))
            .map(elm => ({ key: elm._id, text: elm.name }))
        );
    }
}