import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'page-loader',
    templateUrl: 'page-loader.component.html',
    styleUrls: ['page-loader.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PageLoaderComponent implements AfterViewInit {

    constructor(private _elementRef: ElementRef) { }
    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('page-loader')
    }
}