import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'imageUrlWithLastUpdate',
    standalone: false
})
export class ImageUrlWithLastUpdatePipe implements PipeTransform {

    constructor(
        private _translate: TranslateService,
    ) { }

    transform(value: string, ...args: [{ updatedAt: string | Date }]) {
        const updatedAt = args[0].updatedAt;
        let timeValue: number;
        if (typeof updatedAt === 'string') {
            timeValue = new Date(updatedAt)?.getTime();
        } else {
            timeValue = updatedAt?.getTime();
        }
        if (timeValue) {
            return value + '?t=' + timeValue;
        } else {
            return value;
        }
    }
}