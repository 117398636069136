import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AlbiUiLibraryModule } from "albi-ui/dist/albi-ui-library";
import { ImageCropperComponent } from 'ngx-image-cropper';
import { NgxQrcodeStylingComponent } from "ngx-qrcode-styling";
import { EXPORT_DECLARATIONS } from "./app.routing";


@NgModule({
    declarations: [
        ...EXPORT_DECLARATIONS,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AlbiUiLibraryModule,
        ImageCropperComponent,
        NgxQrcodeStylingComponent,
    ],
    exports: [
        ...EXPORT_DECLARATIONS,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AlbiUiLibraryModule,
        ImageCropperComponent,
        NgxQrcodeStylingComponent,
    ]
})
export class SharedModule { }