import { ChangeDetectionStrategy, Component, DestroyRef, OnDestroy, OnInit, ViewEncapsulation, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { USER_ACCESS_PAGE_ACTIONS } from "src/userAccessStore/userAccessStore.actions";
import { USER_ACCES_SELECTORS } from "src/userAccessStore/userAccessStore.selectors";
import { resolvedEnterpriseCompanyAccess } from "src/userAccessStore/userAccessStore.state";

@Component({
    selector: 'select-enterpriseAccess',
    templateUrl: 'select-enterpriseAccess.component.html',
    styleUrls: ['select-enterpriseAccess.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SelectEnterpriseAccessComponent implements OnInit, OnDestroy {
    private readonly _destroy: DestroyRef = inject(DestroyRef);

    enterpriseCompanyAccesses$ = new BehaviorSubject<resolvedEnterpriseCompanyAccess[]>(null);
    selectedEnterpriseCompanyAccesses$ = new BehaviorSubject<resolvedEnterpriseCompanyAccess>(null);
    canChangeCompany$: Observable<boolean>;

    enterpriseAccessSectionVisible$ = new BehaviorSubject<boolean>(false);

    constructor(private _store: Store) { }

    ngOnInit(): void {
        this.canChangeCompany$ = this._store.select(USER_ACCES_SELECTORS.selectCanChangeUserAcces);
        this._store.select(USER_ACCES_SELECTORS.selectSelectedUserAccesses).pipe(
            tap(userAccess => {
                this._store.select(USER_ACCES_SELECTORS.selectEnterpriseAccesses).pipe(
                    tap(value => {
                        this.enterpriseCompanyAccesses$.next(value.find(elm => elm.companyId === userAccess.company._id)?.companyAccesses);
                    }),
                    takeUntilDestroyed(this._destroy),
                ).subscribe();
                this._store.select(USER_ACCES_SELECTORS.selectSelectedEnterpriseAccess).pipe(
                    tap(value => {
                        this.selectedEnterpriseCompanyAccesses$.next(value);
                    }),
                    takeUntilDestroyed(this._destroy),
                ).subscribe();

            }),
            takeUntilDestroyed(this._destroy),
        ).subscribe();
    }


    ngOnDestroy(): void {
        this._store.dispatch(USER_ACCESS_PAGE_ACTIONS.changeSelectedEnterpriseAccess({ enterpriceAccess: null }));
    }

    changeEnterpriseAccessSectionVisible(ev: boolean) {
        this.enterpriseAccessSectionVisible$.next(ev);
    }


    onEnterpriceCompanyClick(event: resolvedEnterpriseCompanyAccess) {
        const selectedentErpriseCompanyAccesses = this.selectedEnterpriseCompanyAccesses$.value;
        if (selectedentErpriseCompanyAccesses?._id === event._id) {
            this._store.dispatch(USER_ACCESS_PAGE_ACTIONS.changeSelectedEnterpriseAccess({ enterpriceAccess: null }));
        } else {
            this._store.dispatch(USER_ACCESS_PAGE_ACTIONS.changeSelectedEnterpriseAccess({ enterpriceAccess: event }));
        };
        this.enterpriseAccessSectionVisible$.next(false);
    }
}