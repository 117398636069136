"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompanyTypeEnum = void 0;
var CompanyTypeEnum;
(function (CompanyTypeEnum) {
  CompanyTypeEnum["WINERY"] = "winery";
  CompanyTypeEnum["PROFESSIONAL"] = "professional";
})(CompanyTypeEnum = exports.CompanyTypeEnum || (exports.CompanyTypeEnum = {}));
