import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as csc from 'country-state-city';
import { BehaviorSubject } from "rxjs";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";


export type CompanyAddressFormType = {
    name: FormControl<string | null>;
    address: FormControl<string | null>;
    city: FormControl<string>;
    country: FormControl<string | { code: string, name: string } | null>;
    state: FormControl<string>;
    postalCode: FormControl<string | null>;
    isShipping: FormControl<boolean>;
    isBilling: FormControl<boolean>;
    isLocationEnabled: FormControl<boolean>;
}

@Component({
    selector: 'address-form-component',
    templateUrl: 'address-form-component.component.html',
    styleUrls: ['address-form-component.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddressFormComponentComponent {

    @Input() companyAddressForm: FormGroup<CompanyAddressFormType>;

    @Input() formTitle: string;
    @Input() formDescription: string;

    countrySuggestion$ = new BehaviorSubject<{ code: string, name: string }[]>(null);

    public readonly addressFormDictionary = COMPONENTS_DICTIONARY.addressFormComponent;

    constructor(
        private _translate: TranslateService,
    ) { }
    changeCountrySuggestion(event: string) {
        const regionNamesInUserLocale = new Intl.DisplayNames([this._translate.currentLang], { type: 'region' });
        if (Boolean(event)) {
            let suggestions = csc.Country.getAllCountries()
                .map(elm => ({ code: elm.isoCode, name: regionNamesInUserLocale.of(elm.isoCode) }))
                .filter((elm) => {
                    return elm.name.toLowerCase().includes(event?.toLowerCase());
                });
            suggestions.length > 0 ? this.countrySuggestion$.next(suggestions) : this.countrySuggestion$.next(null);
        } else {
            this.countrySuggestion$.next(null);
        }
    }
}