import { IWineVintage } from "@albi-types/wine/wine";
import { IWineSetting } from "@albi-types/wine/wineSetting";
import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, ViewEncapsulation, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ImageCropperResult } from "@dashboard-components/image-crop-component/image-crop.component";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlbiChipType, AlbiOption } from "albi-ui/dist/albi-ui-library";
import { Buffer } from 'buffer';
import { BehaviorSubject, Observable, combineLatest, map, of, take, tap } from "rxjs";
import { BackendService } from "src/services/backend.service";
import { HeaderMessageService } from "src/services/header-message.service";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";
import { SHARED_DICTIONARY } from "src/translations/dictionaries/shared.dictionary";
import { LANGUAGES_OPTIONS } from "src/utils/sharedValues";
import { resolveDBLocaleFields } from "src/utils/utilities";

export type WineImageControlType = {
    fileType: string,
    buffer: string | Blob,
    sizeError: boolean,
    file: File
}

export type VintageCreateFormType = {
    yearType: FormControl<'vintage' | 'n.v.'>;//remove
    year: FormControl<Date>;//edit -> {year.getFullYear()}
    yearDetails: FormControl<string>;//Special Edition
    descriptionLanguage: FormControl<AlbiOption>;
    descriptionText: FormControl<string>;//edit -> description:[{locale:{descLang},description:{descText}}]
    alcohol: FormControl<number>;
    temperature: FormControl<number>;
    primaryFoods: FormControl<AlbiChipType[]>;
    secondaryFoods: FormControl<AlbiChipType[]>;//edit -> pairings:[{name:{food.key},match:'good'|'perfect'}]
    moods: FormControl<AlbiChipType[]>;//edit -> mood.key
    philosophy: FormControl<AlbiChipType[]>;//edit -> philosophy.key
    allergens: FormControl<AlbiChipType[]>;//edit -> allergen.key
    awards: FormControl<AlbiChipType[]>;//edit -> award.text
    labelImage: FormControl<WineImageControlType>;
    bottleImage: FormControl<WineImageControlType>;
}

@Component({
    selector: 'vintage-creation-form',
    templateUrl: 'vintage-creation-form.component.html',
    styleUrls: ['vintage-creation-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class VintageCreationFormComponent implements OnInit {
    private readonly _destroy: DestroyRef = inject(DestroyRef);

    labelImage$ = new BehaviorSubject<string>(null);
    bottleImage$ = new BehaviorSubject<string>(null);

    @Input({ required: true }) vintageCreateForm: FormGroup<VintageCreateFormType>;
    @Input() vintageId: string;

    Boolean = Boolean;

    showVintageYearPicker$: Observable<boolean>;
    isYearDetailRequired$ = new BehaviorSubject<boolean>(false);

    public readonly vintageFormDictionary = COMPONENTS_DICTIONARY.vintageCreationForm;
    public readonly sharedDictionary = SHARED_DICTIONARY;

    labelCropResult$ = new BehaviorSubject<ImageCropperResult>(undefined);
    loadedLabelImageFile$ = new BehaviorSubject<File>(null);

    bottleCropResult$ = new BehaviorSubject<ImageCropperResult>(undefined);
    loadedBottleImageFile$ = new BehaviorSubject<File>(null);

    vintageTypeOptions: { key: string, name: string, description?: string }[];

    languageOptions: AlbiOption[] = LANGUAGES_OPTIONS;

    wineSettings$ = new BehaviorSubject<IWineSetting>(null);
    winePrimaryPairingsSuggestions$ = new BehaviorSubject<AlbiChipType[]>(null);
    wineSecondaryPairingsSuggestions$ = new BehaviorSubject<AlbiChipType[]>(null);
    wineMoodsSuggestions$ = new BehaviorSubject<AlbiChipType[]>(null);
    winePhilosophySuggestions$ = new BehaviorSubject<AlbiChipType[]>(null);
    wineAllergensSuggestions$ = new BehaviorSubject<AlbiChipType[]>(null);

    constructor(
        private _backendService: BackendService,
        private _headerMessageService: HeaderMessageService,
        private _store: Store,
        private _router: Router,
        private _translateService: TranslateService,
    ) { }

    ngOnInit(): void {
        this.vintageCreateForm.controls.temperature.addValidators([Validators.min(5), Validators.max(25)]);

        this.vintageTypeOptions = [
            {
                key: 'vintage',
                name: this._translateService.instant(this.vintageFormDictionary.year.vintageOption.title),
                description: this._translateService.instant(this.vintageFormDictionary.year.vintageOption.description)
            },
            {
                key: 'n.v.',
                name: this._translateService.instant(this.vintageFormDictionary.year.nvOption.title),
                description: this._translateService.instant(this.vintageFormDictionary.year.nvOption.description)
            }
        ];

        combineLatest([
            this._backendService.get<{ wine_setting: IWineSetting }>(`app_settings/wine_setting`, { useCompanyHeader: false }),
            this.vintageId ? this._backendService.get<{ vintage: IWineVintage }>(`wine/wines/vintages/${this.vintageId}?raw=true`) : of(null)
        ]).pipe(
            tap(([wineSettingResponse, vintageResponse]) => {
                this.wineSettings$.next(wineSettingResponse.wine_setting);
                if (vintageResponse) {
                    this.vintageCreateForm.patchValue({
                        yearType: typeof vintageResponse.vintage.year === 'number' ? 'vintage' : 'n.v.',
                        year: typeof vintageResponse.vintage.year === 'number' ? new Date(`06/06/${vintageResponse.vintage.year}`) : null,
                        yearDetails: vintageResponse.vintage.yearDetails,
                        descriptionLanguage: LANGUAGES_OPTIONS.find(elm => elm.key === this._translateService.currentLang),
                        descriptionText: vintageResponse.vintage.description.find(elm => elm.locale === this._translateService.currentLang)?.description,
                        alcohol: vintageResponse.vintage.alcohol,
                        temperature: vintageResponse.vintage.temperature,
                        primaryFoods: vintageResponse.vintage.pairings.filter(elm => elm.match === 'perfect').map(elm => {
                            const pairing = wineSettingResponse.wine_setting.pairings.find(pair => pair.name === elm.name)
                            return ({
                                key: pairing.name,
                                text: resolveDBLocaleFields(pairing.locale, this._translateService.currentLang),
                                textIcon: pairing.icon
                            })
                        }),
                        secondaryFoods: vintageResponse.vintage.pairings.filter(elm => elm.match === 'good').map(elm => {
                            const pairing = wineSettingResponse.wine_setting.pairings.find(pair => pair.name === elm.name)
                            return ({
                                key: pairing.name,
                                text: resolveDBLocaleFields(pairing.locale, this._translateService.currentLang),
                                textIcon: pairing.icon
                            })
                        }),
                        moods: vintageResponse.vintage.moods.map(elm => {
                            const mood = wineSettingResponse.wine_setting.moods.find(moodElm => moodElm.name === elm);
                            return ({
                                key: mood.name,
                                text: resolveDBLocaleFields(mood.locale, this._translateService.currentLang),
                                textIcon: mood.icon
                            })
                        }),
                        philosophy: vintageResponse.vintage.philosophy.map(elm => {
                            const philosophy = wineSettingResponse.wine_setting.philosophy.find(philosophyElm => philosophyElm.name === elm);
                            return ({
                                key: philosophy.name,
                                text: resolveDBLocaleFields(philosophy.locale, this._translateService.currentLang),
                                textIcon: philosophy.icon
                            })
                        }),
                        allergens: vintageResponse.vintage.allergens.map(elm => {
                            const allergen = wineSettingResponse.wine_setting.allergens.find(allergenElm => allergenElm.name === elm);
                            return ({
                                key: allergen.name,
                                text: resolveDBLocaleFields(allergen.locale, this._translateService.currentLang),
                                textIcon: allergen.icon
                            })
                        }),
                        awards: vintageResponse.vintage.awards.map(elm => {
                            return ({
                                key: elm,
                                text: elm,
                                textIcon: '🏆'
                            })
                        }),
                    });
                    this.vintageCreateForm.markAsUntouched();
                    this.vintageCreateForm.markAsPristine();
                    this.bottleImage$.next(`${vintageResponse.vintage.images.bottle.default}?t=${new Date(vintageResponse.vintage.updatedAt as unknown as string).getTime()}`);
                    this.labelImage$.next(`${vintageResponse.vintage.images.label.default}?t=${new Date(vintageResponse.vintage.updatedAt as unknown as string).getTime()}`);
                }

            }),
            take(1)
        ).subscribe();


        this.vintageCreateForm.controls.yearDetails.valueChanges.pipe(
            tap(_ => {
                this.isYearDetailRequired$.next(this.vintageCreateForm.controls.yearDetails.hasValidator(Validators.required))
            }),
            takeUntilDestroyed(this._destroy)
        ).subscribe();

        this.showVintageYearPicker$ = this.vintageCreateForm.controls.yearType.valueChanges.pipe(
            tap(value => {
                if (value === 'vintage') {
                    //Make year selector required
                    this.vintageCreateForm.controls.year.addValidators(Validators.required);
                    this.vintageCreateForm.controls.year.updateValueAndValidity();
                    //Make year Details Not Required
                    this.vintageCreateForm.controls.yearDetails.clearValidators();
                    this.vintageCreateForm.controls.yearDetails.removeValidators(Validators.required);
                    this.vintageCreateForm.controls.yearDetails.updateValueAndValidity();

                } else {
                    //Make year selector NOT required
                    this.vintageCreateForm.controls.year.clearValidators();
                    this.vintageCreateForm.controls.year.removeValidators(Validators.required);
                    this.vintageCreateForm.controls.year.updateValueAndValidity();
                    //Make year Detail required
                    this.vintageCreateForm.controls.yearDetails.addValidators(Validators.required);
                    this.vintageCreateForm.controls.yearDetails.updateValueAndValidity();
                }
            }),
            map(value => value === 'vintage')
        );
    }


    hasValidatorRequire(control: FormControl) {
        return (control.validator === Validators.required);
    }

    //COMPLETE METHODS
    completePairingsSuggestions(ev: string, type: 'primary' | 'secondary', addAll?: boolean) {
        let newSuggestions: AlbiChipType[] = null;
        if (ev || addAll) {
            const usedPairings = (this.vintageCreateForm.controls.primaryFoods.getRawValue() || []).concat(this.vintageCreateForm.controls.secondaryFoods.getRawValue());

            newSuggestions = this.wineSettings$.value.pairings
                //filter foods by user text
                .filter(elm => elm.locale[this._translateService.currentLang].toLowerCase().includes(ev.toLowerCase()))
                //map to use the result as chips suggestion
                .map(elm => ({ key: elm.name, text: elm.locale[this._translateService.currentLang], textIcon: elm.icon }))
                //filter to remove already used foods
                .filter(elm => !(usedPairings.some(val => val?.key === elm?.key)));
            newSuggestions = newSuggestions?.length > 0 ? newSuggestions : null;
        }
        switch (type) {
            case "primary":
                this.winePrimaryPairingsSuggestions$.next(newSuggestions);
                break;
            case "secondary":
                this.wineSecondaryPairingsSuggestions$.next(newSuggestions);
                break;
        }
    }
    completeMoodsSuggestions(ev: string, addAll?: boolean) {
        let newSuggestions: AlbiChipType[] = null;
        if (ev || addAll) {
            const usedMoods = this.vintageCreateForm.controls.moods.getRawValue();
            newSuggestions = this.wineSettings$.value.moods
                .filter(elm => elm.locale[this._translateService.currentLang].toLowerCase().includes(ev.toLowerCase()))
                .map(elm => ({ text: elm.locale[this._translateService.currentLang], key: elm.name, textIcon: elm.icon }))
                .filter(elm => !usedMoods?.some(val => val?.key === elm?.key));
            newSuggestions = newSuggestions?.length > 0 ? newSuggestions : null;
        }
        this.wineMoodsSuggestions$.next(newSuggestions)
    }
    completePhilosophySuggestions(ev: string, addAll?: boolean) {
        let newSuggestions: AlbiChipType[] = null;
        if (ev || addAll) {
            const usedPhilosophy = this.vintageCreateForm.controls.philosophy.getRawValue();
            newSuggestions = this.wineSettings$.value.philosophy
                .filter(elm => elm.locale[this._translateService.currentLang].toLowerCase().includes(ev.toLowerCase()))
                .map(elm => ({ text: elm.locale[this._translateService.currentLang], key: elm.name, textIcon: elm.icon }))
                .filter(elm => !usedPhilosophy?.some(val => val?.key === elm?.key));
            newSuggestions = newSuggestions?.length > 0 ? newSuggestions : null;
        }
        this.winePhilosophySuggestions$.next(newSuggestions)
    }
    completeAllergensSuggestions(ev: string, addAll?: boolean) {
        let newSuggestions: AlbiChipType[] = null;
        if (ev || addAll) {
            const usedAllergens = this.vintageCreateForm.controls.allergens.getRawValue();
            newSuggestions = this.wineSettings$.value.allergens
                .filter(elm => elm.locale[this._translateService.currentLang].toLowerCase().includes(ev.toLowerCase()))
                .map(elm => ({ text: elm.locale[this._translateService.currentLang], key: elm.name, textIcon: elm.icon }))
                .filter(elm => !(usedAllergens?.some(val => val?.key === elm?.key)));
            newSuggestions = newSuggestions?.length > 0 ? newSuggestions : null;
        }
        this.wineAllergensSuggestions$.next(newSuggestions)
    }

    removeImage(variant: 'bottle' | 'label') {
        switch (variant) {
            case "bottle":
                this.vintageCreateForm.controls.bottleImage.reset();
                this.vintageCreateForm.controls.bottleImage.markAsPristine();
                this.bottleImage$.next(null);
                break;
            case "label":
                this.vintageCreateForm.controls.labelImage.reset();
                this.vintageCreateForm.controls.labelImage.markAsPristine();
                this.labelImage$.next(null);
        }
    }

    //Handle Label image crop
    updateLabelImageResult(ev: ImageCropperResult) {
        this.labelCropResult$.next(ev);
    }

    applyLabelCrop() {
        const labelCropResultValue = this.labelCropResult$.getValue();
        this.labelImage$.next(labelCropResultValue.imageUrl);

        this.vintageCreateForm.controls.labelImage.setValue({
            sizeError: false,
            file: null,
            fileType: 'image/png',
            buffer: labelCropResultValue.blob,
        });
        this.vintageCreateForm.controls.labelImage.markAsDirty();
        this.removeLoadedLabelFile();
    }

    removeLoadedLabelFile() {
        this.loadedLabelImageFile$.next(null)
    }
    //Handle Bottle image crop
    updateBottleImageResult(ev: ImageCropperResult) {
        this.bottleCropResult$.next(ev);
    }

    applyBottleCrop() {
        const bottleCropResultValue = this.bottleCropResult$.getValue();
        this.bottleImage$.next(bottleCropResultValue.imageUrl);

        this.vintageCreateForm.controls.bottleImage.setValue({
            sizeError: false,
            file: null,
            fileType: 'image/png',
            buffer: bottleCropResultValue.blob,
        });
        this.vintageCreateForm.controls.bottleImage.markAsDirty();
        this.removeLoadedBottleFile();
    }

    removeLoadedBottleFile() {
        this.loadedBottleImageFile$.next(null)
    }

    //IMAGES HANDLERS
    handleAddLabel() {
        // Create a new file input element
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/png, image/jpg, image/jpeg'; // Accept only image files

        // Add a change event listener to handle file selection
        fileInput.addEventListener('change', (event: Event) => this.onImageSelected(event, 'label'));

        // Programmatically click the file input
        fileInput.click();

    }
    handleAddBottle() {
        // Create a new file input element
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/png, image/jpg, image/jpeg'; // Accept only image files

        // Add a change event listener to handle file selection
        fileInput.addEventListener('change', (event: Event) => this.onImageSelected(event, 'bottle'));

        // Programmatically click the file input
        fileInput.click();

    }
    // Method to handle file selection
    private onImageSelected(event: Event, variant: 'bottle' | 'label'): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            if (
                file.type !== 'image/png' &&
                file.type !== 'image/jpg' &&
                file.type !== 'image/jpeg'
            ) {
                return
            }
            // Handle the selected file
            const reader = new FileReader();

            //reader.readAsDataURL(file);
            reader.readAsArrayBuffer(file);
            reader.onload = (ev) => {
                const base64String = `data:${file.type};base64,` + Buffer.from(reader.result as Uint8Array).toString('base64');

                //check size
                const img = new Image();
                let sizeError = false;
                img.src = base64String;
                img.onload = () => {
                    if (variant === 'label' && img.height !== 640 && img.width !== 480) {
                        sizeError = true;
                    } else if (variant === 'bottle' && img.height !== 960) {
                        sizeError = true;
                    }

                    if (sizeError) {
                        switch (variant) {
                            case "bottle":
                                this.loadedBottleImageFile$.next(file);
                                break;
                            case "label":
                                this.loadedLabelImageFile$.next(file);
                                break;
                        }
                    } else {
                        switch (variant) {
                            case "bottle":
                                this.vintageCreateForm.controls.bottleImage.setValue({ fileType: file.type, buffer: reader.result as string, sizeError, file });
                                this.vintageCreateForm.controls.bottleImage.markAsDirty();
                                this.bottleImage$.next(base64String);
                                break;
                            case "label":
                                this.vintageCreateForm.controls.labelImage.setValue({ fileType: file.type, buffer: reader.result as string, sizeError, file });
                                this.vintageCreateForm.controls.labelImage.markAsDirty();
                                this.labelImage$.next(base64String);
                                break;
                        }
                    }
                }
            }
        }
    }

}