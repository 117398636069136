export const COMPONENTS_DICTIONARY = {
    addressFormComponent: {
        nameInput: {
            title: 'components.addressFormComponent.nameInput.title',
            description: 'components.addressFormComponent.nameInput.description',
        },
        addressCheckbox: {
            shippingText: 'components.addressFormComponent.addressCheckbox.shippingText',
            billingText: 'components.addressFormComponent.addressCheckbox.billingText',
        },
        addressInput: {
            title: 'components.addressFormComponent.addressInput.title',
            description: 'components.addressFormComponent.addressInput.description',
            placeholder: 'components.addressFormComponent.addressInput.placeholder',
        },
        cityInput: {
            placeholder: 'components.addressFormComponent.cityInput.placeholder',
        },
        stateInput: {
            placeholder: 'components.addressFormComponent.stateInput.placeholder',
        },
        countryInput: {
            placeholder: 'components.addressFormComponent.countryInput.placeholder',
        },
        postalCodeInput: {
            placeholder: 'components.addressFormComponent.postalCodeInput.placeholder',
        },
    },
    appTopbar: {
        searchbarPlaceholder: `components.appTopbar.searchbarPlaceholder`,
        changeCompany: {
            title: `components.appTopbar.changeCompany.title`,
            description: `components.appTopbar.changeCompany.description`,
            button: `components.appTopbar.changeCompany.button`,
        },
        unchangeableCompany: {
            title: `components.appTopbar.unchangeableCompany.title`,
            description: `components.appTopbar.unchangeableCompany.description`,
        }
    },
    appPageLayout: {
        staticMessage: {
            handleNewConnection: `components.appPageLayout.staticMessage.handleNewConnection`,
            deleteRequestMessage: `components.appPageLayout.staticMessage.deleteRequestMessage`,
            pendingDocumentsMessage: `components.appPageLayout.staticMessage.pendingDocumentsMessage`,
        },
        feedbackButton: `components.appPageLayout.feedbackButton`,
        sentryFeedback: {
            nameLabel: `components.appPageLayout.sentryFeedback.nameLabel`,
            namePlaceholder: `components.appPageLayout.sentryFeedback.namePlaceholder`,
            triggerLabel: `components.appPageLayout.sentryFeedback.triggerLabel`,
            emailLabel: `components.appPageLayout.sentryFeedback.emailLabel`,
            emailPlaceholder: `components.appPageLayout.sentryFeedback.emailPlaceholder`,
            messageLabel: `components.appPageLayout.sentryFeedback.messageLabel`,
            messagePlaceholder: `components.appPageLayout.sentryFeedback.messagePlaceholder`,
            isRequiredLabel: `components.appPageLayout.sentryFeedback.isRequiredLabel`,
            formTitle: `components.appPageLayout.sentryFeedback.formTitle`,
            submitButtonLabel: `components.appPageLayout.sentryFeedback.submitButtonLabel`,
            cancelButtonLabel: `components.appPageLayout.sentryFeedback.cancelButtonLabel`,
            addScreenshotButtonLabel: `components.appPageLayout.sentryFeedback.addScreenshotButtonLabel`,
            removeScreenshotButtonLabel: `components.appPageLayout.sentryFeedback.removeScreenshotButtonLabel`,
        }
    },
    companyCreationForm: {
        typeInput: {
            title: 'components.companyCreationForm.typeInput.title',
            description: 'components.companyCreationForm.typeInput.description',
            wineryBox: {
                title: 'components.companyCreationForm.typeInput.wineryBox.title',
                description: 'components.companyCreationForm.typeInput.wineryBox.description',
            },
            profesionalBox: {
                title: 'components.companyCreationForm.typeInput.profesionalBox.title',
                description: 'components.companyCreationForm.typeInput.profesionalBox.description',
            }
        },
        imageInput: {
            title: 'components.companyCreationForm.imageInput.title',
            description: 'components.companyCreationForm.imageInput.description',
        },
        nameInput: {
            companyTitle: 'components.companyCreationForm.nameInput.companyTitle',
            wineryTitle: 'components.companyCreationForm.nameInput.wineryTitle',
            description: 'components.companyCreationForm.nameInput.description',
        },
        emailInput: {
            title: 'components.companyCreationForm.emailInput.title',
            description: 'components.companyCreationForm.emailInput.description',
        },
        fullNameInput: {
            title: 'components.companyCreationForm.fullNameInput.title',
            description: 'components.companyCreationForm.fullNameInput.description',
        },
        vatNumberInput: {
            title: 'components.companyCreationForm.vatNumberInput.title',
            description: 'components.companyCreationForm.vatNumberInput.description',
        },
        websiteInput: {
            title: 'components.companyCreationForm.websiteInput.title',
            description: 'components.companyCreationForm.websiteInput.description',
        },
        phoneInput: {
            title: 'components.companyCreationForm.phoneInput.title',
            description: 'components.companyCreationForm.phoneInput.description',
        },
    },
    impressumBox: {
        description: `components.impressumBox.description`,
        nation: `components.impressumBox.nation`,
        inputPlaceholder: `components.impressumBox.inputPlaceholder`,
    },
    wineCreationForm: {
        title: `components.wineCreationForm.title`,
        description: `components.wineCreationForm.description`,
        nameInput: {
            title: `components.wineCreationForm.nameInput.title`,
            description: `components.wineCreationForm.nameInput.description`,
        },
        colorInput: {
            title: `components.wineCreationForm.colorInput.title`,
            description: `components.wineCreationForm.colorInput.description`,
        },
        styleInput: {
            title: `components.wineCreationForm.styleInput.title`,
            description: `components.wineCreationForm.styleInput.description`,
        },
        regionInput: {
            title: `components.wineCreationForm.regionInput.title`,
            description: `components.wineCreationForm.regionInput.description`,
        },
        grapeInput: {
            title: `components.wineCreationForm.grapeInput.title`,
            description: `components.wineCreationForm.grapeInput.description`,
        },
        selectedWineView: {
            title: `components.wineCreationForm.selectedWineView.title`,
            name: `components.wineCreationForm.selectedWineView.name`,
            color: `components.wineCreationForm.selectedWineView.color`,
            region: `components.wineCreationForm.selectedWineView.region`,
            grapes: `components.wineCreationForm.selectedWineView.grapes`,
        }
    },
    vintageCreationForm: {
        title: `components.vintageCreationForm.title`,
        description: `components.vintageCreationForm.description`,
        year: {
            title: `components.vintageCreationForm.year.title`,
            description: `components.vintageCreationForm.year.description`,
            vintageOption: {
                title: `components.vintageCreationForm.year.vintageOption.title`,
                description: `components.vintageCreationForm.year.vintageOption.description`,
                vintagePlaceholder: `components.vintageCreationForm.year.vintageOption.vintagePlaceholder`,
            },
            nvOption: {
                title: `components.vintageCreationForm.year.nvOption.title`,
                description: `components.vintageCreationForm.year.nvOption.description`,
            },
            yearDetailPlaceholder: `components.vintageCreationForm.year.yearDetailPlaceholder`,
        },
        images: {
            title: `components.vintageCreationForm.images.title`,
            description: `components.vintageCreationForm.images.description`,
            labelImage: {
                title: `components.vintageCreationForm.images.labelImage.title`,
                description: `components.vintageCreationForm.images.labelImage.description`,
            },
            bottleImage: {
                title: `components.vintageCreationForm.images.bottleImage.title`,
                description: `components.vintageCreationForm.images.bottleImage.description`,
            },
            errorMessage: `components.vintageCreationForm.images.errorMessage`,
        },
        descriptionInput: {
            title: `components.vintageCreationForm.descriptionInput.title`,
            description: `components.vintageCreationForm.descriptionInput.description`,
            langPlaceholder: `components.vintageCreationForm.descriptionInput.langPlaceholder`,
        },
        alcohol: {
            title: `components.vintageCreationForm.alcohol.title`,
            description: `components.vintageCreationForm.alcohol.description`,
        },
        temperature: {
            title: `components.vintageCreationForm.temperature.title`,
            description: `components.vintageCreationForm.temperature.description`,
        },
        primaryPairings: {
            title: `components.vintageCreationForm.primaryPairings.title`,
            description: `components.vintageCreationForm.primaryPairings.description`,
        },
        secondaryPairings: {
            title: `components.vintageCreationForm.secondaryPairings.title`,
            description: `components.vintageCreationForm.secondaryPairings.description`,
        },
        mood: {
            title: `components.vintageCreationForm.mood.title`,
            description: `components.vintageCreationForm.mood.description`,
        },
        philosophy: {
            title: `components.vintageCreationForm.philosophy.title`,
            description: `components.vintageCreationForm.philosophy.description`,
        },
        allergen: {
            title: `components.vintageCreationForm.allergen.title`,
            description: `components.vintageCreationForm.allergen.description`,
        },
        awards: {
            title: `components.vintageCreationForm.awards.title`,
            description: `components.vintageCreationForm.awards.description`,
        },
        cropLabelPanel: {
            title: `components.vintageCreationForm.cropLabelPanel.title`,
            description: `components.vintageCreationForm.cropLabelPanel.description`,
        },
        cropBottlePanel: {
            title: `components.vintageCreationForm.cropBottlePanel.title`,
            description: `components.vintageCreationForm.cropBottlePanel.description`,
        }
    },
    userRoleForm: {
        access: {
            title: `components.userRoleForm.access.title`,
            description: `components.userRoleForm.access.description`,
            inviteUserDescription: `components.userRoleForm.access.inviteUserDescription`,
            locationOption: `components.userRoleForm.access.locationOption`,
            companyOption: `components.userRoleForm.access.companyOption`,
        },
        role: {
            title: `components.userRoleForm.role.title`,
            locationRoleDescription: `components.userRoleForm.role.locationRoleDescription`,
            companyRoleDescription: `components.userRoleForm.role.companyRoleDescription`,
            addLocationButton: `components.userRoleForm.role.addLocationButton`,
        }
    }
}