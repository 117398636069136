import IUser, { IUserCompanyAccess, UserAccessStatusEnum } from "@albi-types/base/user";
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, EventEmitter, HostListener, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { Store } from "@ngrx/store";
import { AlbiButtonColorEnum, TabSectionType } from "albi-ui/dist/albi-ui-library";
import { BehaviorSubject, filter, map, Observable, of, startWith, tap } from "rxjs";
import { AUTHENTICATION_SELECTORS } from "src/authentication/store/authentication.selectors";
import { USER_ACCES_SELECTORS } from "src/userAccessStore/userAccessStore.selectors";
import { AlbiUser, RELEASE_NUMBER, ResolvedUserAccessType } from "src/utils/sharedValues";

import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as Sentry from "@sentry/angular";
import { BackendService } from "src/services/backend.service";
import { MessageType } from "src/services/message.service";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";
import { SHARED_DICTIONARY } from "src/translations/dictionaries/shared.dictionary";

const MAX_MOBILE_PIXEL: number = 1020;

@Component({
    selector: 'app-page-layout',
    templateUrl: 'app-page-layout.component.html',
    styleUrls: ['app-page-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppPageLayoutComponent implements OnInit, AfterViewInit, OnChanges {
    private readonly _destroy: DestroyRef = inject(DestroyRef);

    @Input() verticalTabs: TabSectionType[];
    @Input() showTopbar: boolean = true;
    @Input() isSidebarReduced: boolean;
    @Input() pageHeaderMessages: (MessageType & { linkText?: string, linkUrl?: string })[];

    @Output() changeSidebarReduced = new EventEmitter<boolean>();

    @ViewChild('pageNotification') pageNotification: ElementRef<HTMLDivElement>;

    isSidebarReduced$ = new BehaviorSubject<boolean>(true);

    AlbiButtonColorEnum = AlbiButtonColorEnum;
    companyHasPendingUsers$: Observable<boolean>;

    public theme = new BehaviorSubject<'dark' | 'light'>('light');
    public showEnterpriseCompanyAccessPanel$: Observable<boolean>;
    RELEASE_NUMBER = RELEASE_NUMBER;

    public readonly pageLayoutDictionary = COMPONENTS_DICTIONARY.appPageLayout;
    public readonly sharedDictionary = SHARED_DICTIONARY;

    selectedUserAccess$: Observable<ResolvedUserAccessType>;

    isMobileView$ = new BehaviorSubject<boolean>(false);

    user$: Observable<IUser>;

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        if (window.innerWidth < MAX_MOBILE_PIXEL) {
            this.isMobileView$.next(true);
        } else {
            this.isMobileView$.next(false);
        }
    }

    constructor(
        private _store: Store,
        private _elementRef: ElementRef,
        private _translateService: TranslateService,
        private _backendService: BackendService,
        private _router: Router,
    ) { }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isSidebarReduced']) {
            this.changeIsSidebarReduced(changes['isSidebarReduced']?.currentValue)
        }
    }


    ngOnInit(): void {
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            tap(event => {
                if (!this.isSidebarReduced$.value && this.isMobileView$.value) {
                    this.isSidebarReduced$.next(true);
                }
            })
        ).subscribe();
        this.selectedUserAccess$ = this._store.select(USER_ACCES_SELECTORS.selectSelectedUserAccesses);
        if (!Boolean(this.verticalTabs)) {

            this._translateService.onLangChange.pipe(
                startWith(this._translateService.currentLang),
                tap(value => {
                    this.verticalTabs = [
                        {
                            items: [
                                {
                                    name: this._translateService.instant(this.sharedDictionary.dashboardTabs.home),
                                    iconName: 'menu.home',
                                    navigationUrl: 'dashboard',
                                    disabled: true,
                                },
                                {
                                    name: this._translateService.instant(this.sharedDictionary.dashboardTabs.elabel),
                                    iconName: 'stats.statsLabel',
                                    navigationUrl: 'dashboard/elabel',
                                    disabled: true,
                                },
                                {
                                    name: this._translateService.instant(this.sharedDictionary.dashboardTabs.wines),
                                    iconName: 'wine.grapeVariety',
                                    navigationUrl: 'dashboard/wines',
                                    disabled: true,
                                },
                            ]
                        }, {
                            isBottomSection: true,
                            items: [
                                {
                                    name: this._translateService.instant(this.sharedDictionary.dashboardTabs.company),
                                    iconName: 'legal.businessAccount',
                                    navigationUrl: 'dashboard/company',
                                    disabled: true,
                                },
                                {
                                    name: this._translateService.instant(this.sharedDictionary.dashboardTabs.profile),
                                    iconName: 'menu.profile',
                                    navigationUrl: 'profile/info',
                                }
                            ]
                        }
                    ];
                }),
                takeUntilDestroyed(this._destroy)
            ).subscribe();
        }
        if (this.isSidebarReduced) {
            this.isSidebarReduced$.next(this.isSidebarReduced);
        }
        this.showEnterpriseCompanyAccessPanel$ = this._store.select(USER_ACCES_SELECTORS.selectShowEnterpriseAccessPanel);
        this.user$ = this._store.select(AUTHENTICATION_SELECTORS.selectLoggedUser);
        //get company pending accesses if user is admin
        try {
            this._store.select(USER_ACCES_SELECTORS.selectSelectedUserAccesses).pipe(
                tap(userAccess => {
                    this.companyHasPendingUsers$ = userAccess?.role?.name === 'Admin' ? this._backendService.get<{ user_accesses: (IUserCompanyAccess & { user: AlbiUser })[] }>(`companies/${userAccess.company._id}/user_accesses`).pipe(
                        map(response => {
                            return response.user_accesses.filter(access => access.status === UserAccessStatusEnum.PENDING)?.length > 0
                        })
                    ) : of(false);
                }),
                takeUntilDestroyed(this._destroy),
            ).subscribe();
        } catch (err) {
            this.companyHasPendingUsers$ = of(false);
        }

        this.isSidebarReduced$.pipe(
            tap(value => this.changeSidebarReduced.emit(value)),
            takeUntilDestroyed(this._destroy)
        ).subscribe();
    }

    ngAfterViewInit(): void {
        if (window.innerWidth < MAX_MOBILE_PIXEL) {
            this.isMobileView$.next(true);
        } else {
            this.isMobileView$.next(false);
        }
        this._elementRef.nativeElement.classList.add('app-component');
        // this._headerMessageService.setMessagePanel(document.getElementById('dashboard-message-panel'));
    }

    onChangeTheme(ev: 'dark' | 'light') {
        this.theme.next(ev);
        document.getElementsByTagName('body')[0].style.colorScheme = ev;
    }

    async sentryFeedback() {
        const feedback = Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            autoInject: false,
            colorScheme: "system",
            nameLabel: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.nameLabel),
            namePlaceholder: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.namePlaceholder),
            emailLabel: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.emailLabel),
            emailPlaceholder: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.emailPlaceholder),
            messageLabel: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.messageLabel),
            messagePlaceholder: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.messagePlaceholder),
            isRequiredLabel: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.isRequiredLabel),
            triggerLabel: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.triggerLabel),
            formTitle: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.formTitle),
            submitButtonLabel: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.submitButtonLabel),
            isNameRequired: false,
            isEmailRequired: true,
            cancelButtonLabel: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.cancelButtonLabel),
            addScreenshotButtonLabel: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.addScreenshotButtonLabel),
            removeScreenshotButtonLabel: this._translateService.instant(this.pageLayoutDictionary.sentryFeedback.removeScreenshotButtonLabel),
            accentBackground: '#ff0050'
        });
        const form = await feedback.createForm();
        form.appendToDom();
        form.open();
    }

    changeIsSidebarReduced(ev: boolean) {
        this.isSidebarReduced$.next(ev);
    }
}