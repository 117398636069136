import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AlbiChipType, AlbiOption } from "albi-ui/dist/albi-ui-library";
import { Country } from "country-state-city";
import { BehaviorSubject } from "rxjs";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";
import { LIST_OF_EU_COUNTRIES } from "src/utils/sharedValues";

export type ImpressumControlGroupType = {
    dropdownControl?: FormControl<AlbiOption>;
    textControl: FormControl<string>;
    countryControl: FormControl<AlbiChipType[]>;
};

@Component({
    selector: 'impressum-box',
    templateUrl: 'impressum-box.component.html',
    styleUrls: ['impressum-box.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ImpressumBoxComponent {

    @Input() dropdownOptions: AlbiOption[];
    @Input() inputTextType: 'textArea' | 'input' = 'textArea';
    @Input() formGroup: FormGroup<ImpressumControlGroupType>;

    @Output() onClose = new EventEmitter<PointerEvent>();

    public readonly impressumBoxDictionary = COMPONENTS_DICTIONARY.impressumBox;

    countrySuggestions$ = new BehaviorSubject<AlbiChipType[]>(null)

    constructor() { }

    completeCountrySuggestions(ev: string, completeAll?: boolean) {
        let newSuggestions: AlbiChipType[] = null;
        if (ev || completeAll) {
            const selectedCountries = this.formGroup.controls.countryControl.getRawValue() || [];
            newSuggestions = LIST_OF_EU_COUNTRIES
                .filter(elm => !selectedCountries.some(sel => sel.key === elm))
                .map(elm => {
                    const country = Country.getCountryByCode(elm);
                    if (country) {
                        return ({
                            key: elm,
                            text: country.name,
                            textIcon: country.flag
                        })
                    }
                    return null
                }).filter(elm => !ev || elm.text.toLowerCase().includes(ev.toLowerCase()));
            /*if (countries.length > 0) {
                countries.unshift({ key: 'all', text: 'All' })
            }*/
        }
        this.countrySuggestions$.next(newSuggestions);
    }

    onCloseClick(ev: PointerEvent) {
        this.onClose.emit(ev);
    }
}