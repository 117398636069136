import { CompanyTypeEnum, ICompany } from "@albi-types/base/company";
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Buffer } from 'buffer';
import { BehaviorSubject, Observable, startWith } from "rxjs";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";



export type CompanyCreateFormType = {
    type: FormControl<ICompany['type'] | null>;
    name: FormControl<string | null>;
    fullName: FormControl<string | null>;
    email: FormControl<string | null>;
    vatNumber: FormControl<string | null>;
    website: FormControl<string | null>;
    phone: FormControl<string | null>;
    companyImage: FormControl<{ fileType: string, buffer: string }>;
}


@Component({
    selector: 'company-creation-form',
    templateUrl: 'company-creation-form.component.html',
    styleUrls: ['company-creation-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CompanyCreationFormComponent implements OnInit {
    private readonly _destroy: DestroyRef = inject(DestroyRef);

    public companyLogoSrc$ = new BehaviorSubject<string>(undefined);

    public readonly companyFormDictionary = COMPONENTS_DICTIONARY.companyCreationForm;

    @Input() companyCreateForm: FormGroup<CompanyCreateFormType>;

    @Input() formTitle: string;
    @Input() formDescription: string;

    @Input() companyImage: string;

    @Input() canInsertCompanyLogo: boolean = true;

    companyType$: Observable<ICompany['type']>;

    CompanyTypeEnum = CompanyTypeEnum;

    constructor() { }
    ngOnInit(): void {
        this.companyType$ = this.companyCreateForm.controls.type.valueChanges.pipe(
            startWith(this.companyCreateForm.controls.type.value)
        )
    }


    loadCompanyImage() {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/png, image/jpg, image/jpeg'; // Accept only image files

        // Add a change event listener to handle file selection
        fileInput.addEventListener('change', (event: Event) => this.onImageSelected(event));

        // Programmatically click the file input
        fileInput.click();

    }


    // Method to handle file selection
    private onImageSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            if (
                file.type !== 'image/png' &&
                file.type !== 'image/jpg' &&
                file.type !== 'image/jpeg'
            ) {
                return
            }
            // Handle the selected file
            const reader = new FileReader();

            //reader.readAsDataURL(file);
            reader.readAsArrayBuffer(file);
            reader.onload = (ev) => {

                const base64String = `data:${file.type};base64,` + Buffer.from(reader.result as Uint8Array).toString('base64');
                this.companyLogoSrc$.next(base64String);
                this.companyCreateForm.controls.companyImage.setValue({ buffer: (reader.result as string), fileType: file.type });
                this.companyCreateForm.controls.companyImage.markAsDirty();
            }
        }
    }
}