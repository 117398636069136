import { UserSession } from '@albi-types/base/user';
import { createAction, props } from '@ngrx/store';
import { ResolvedUserAccessType } from 'src/utils/sharedValues';
import { USER_ACCESS_FEATURE, resolvedEnterpriseCompanyAccess } from './userAccessStore.state';

export const USER_ACCESS_PAGE_ACTIONS = {
    initUserAcces: createAction(`[${USER_ACCESS_FEATURE}] Init User Acces`),
    changeSelectedUserAcces: createAction(`[${USER_ACCESS_FEATURE}] Change Selected User Acces`, props<{ companyAccess: ResolvedUserAccessType }>()),
    setCanChangeUserAcces: createAction(`[${USER_ACCESS_FEATURE}] Set Can Change User Acces`, props<{ value: boolean }>()),
    updateUserAccesses: createAction(`[${USER_ACCESS_FEATURE}] Update User Accesses`, props<{ selectedAccessId?: string, selectedCompanyId?: string, selectedEnterpriseCompanyAccess?: string }>()),
    changeSelectedEnterpriseAccess: createAction(`[${USER_ACCESS_FEATURE}] Change Selected Enterprise Access`, props<{ enterpriceAccess: resolvedEnterpriseCompanyAccess }>()),
    changeShowEnterprisePanelAccess: createAction(`[${USER_ACCESS_FEATURE}] Change Show Enterprise Panel Access`, props<{ showEnterpriseAccessPanel: boolean }>()),
};

export const USER_ACCESS_API_ACTIONS = {
    initUserAccesSuccess: createAction(
        `[${USER_ACCESS_FEATURE}] Init User Access Success`,
        props<{
            userAccesses: UserSession['companyAccesses'],
            selectedUserAccess: ResolvedUserAccessType,
            enterpriseAccesses?: { companyId: string; companyAccesses: resolvedEnterpriseCompanyAccess[]; }[],
            selectedEnterpriseAccess?: resolvedEnterpriseCompanyAccess,
            canChangeUserAccess: boolean,
        }>()
    ),
    initUserAccesFailure: createAction(
        `[${USER_ACCESS_FEATURE}] Init User Access Failure`,
        props<{ error: string }>()

    ),


    updateUserAccessessSuccess: createAction(
        `[${USER_ACCESS_FEATURE}] Update User Accessess Success`,
        props<{
            userAccesses: UserSession['companyAccesses'],
            selectedUserAccess: ResolvedUserAccessType,
            enterpriseAccesses?: { companyId: string; companyAccesses: resolvedEnterpriseCompanyAccess[]; }[],
            selectedEnterpriseAccess?: resolvedEnterpriseCompanyAccess,
        }>()
    ),
    updateUserAccessessFailure: createAction(
        `[${USER_ACCESS_FEATURE}] Update User Accessess Failure`,
        props<{ error: string }>()
    ),
};

