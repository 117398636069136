<ng-container *ngIf="{
    user:user$ | async,
    mainBarSuggestions: mainBarSuggestions$ | async,
    companyAccesses: companyAccesses$ | async,
    canChangeCompany: canChangeCompany$ | async,
    selectedCompanyAccess: selectedCompanyAccess$ | async,
    showChangeCompanyPanel:showChangeCompanyPanel$ | async,
} as vm">
    <div class="page-header">
        <ng-content></ng-content>
        <div class="user-space-container" (click)="redirectToUserPage()">
            <img class="avatar-image small" onerror="this.src='assets/user-avatar.png'"
                [src]="vm.user?.avatar | imageUrlWithLastUpdate: {updatedAt:vm.user?.updatedAt} " height="32px"
                width="32px">
            <div class="user-text-info">{{vm.user?.name}}</div>
        </div>
        <div class="main-search-wrapper">
            <albi-autocomplete [placeholder]="appTopbarDictionary.searchbarPlaceholder | translate"
                [suggestions]="vm.mainBarSuggestions" [control]="searchBarControl"
                (completeMethod)="completeMainBarSearch($event)" [isFluidElement]="true">
                <ng-template #itemTemplate let-suggestion>
                    <div class="main-suggestion-wrapper" (click)="mainSuggestionClick(suggestion)">
                        <p>{{suggestion.path}}</p>
                        <h3>{{suggestion.label}}</h3>

                    </div>
                </ng-template>
            </albi-autocomplete>
        </div>
        <div class="d-flex gap-24 align-center">
            <div>
                <albi-button label="Consumi e piani" buttonVariant="textOnly" iconName="redirect"
                    (onClick)="requestStripeHandlePage(vm.selectedCompanyAccess.company)"></albi-button>
            </div>
            <div class="company-container">
                <img [src]="vm.selectedCompanyAccess?.company?.image | imageUrlWithLastUpdate: {updatedAt: vm.selectedCompanyAccess?.company?.updatedAt} "
                    onerror="this.src='assets/company-avatar.png'" alt="" height="32px" width="32px"
                    class="avatar-image small contain">
                <div class="company-name">
                    <div class="text-sm font-bold overflow-ellipse">
                        {{vm.selectedCompanyAccess?.company?.name || 'No Company'}}
                    </div>
                    <div *ngIf="vm.selectedCompanyAccess?.location" class="text-xs font-regular overflow-ellipse">
                        {{vm.selectedCompanyAccess.location?.name}}</div>
                </div>
                <albi-icon name="arrow.repeat" (onClick)="changeShowChangeCompanyPanel(!vm.showChangeCompanyPanel)"
                    class="flex-shrink-0"></albi-icon>
                <div class="company-change-panel" *ngIf="vm.showChangeCompanyPanel">
                    <div class="">
                        <ng-container *ngIf="vm.canChangeCompany;else unchangeableCompanyTemplate">
                            <p class="title">{{appTopbarDictionary.changeCompany.title | translate}}</p>
                            <div class="subtitle">{{appTopbarDictionary.changeCompany.description | translate}}</div>
                            <div class="company-selector-list">
                                <div *ngFor="let companyAccess of vm.companyAccesses" class="change-company-selector">
                                    <div class="company-wrapper selectable-element"
                                        (click)="changeSelectedCompany(companyAccess)">
                                        <div class="d-flex align-center gap-1">
                                            <img [src]="companyAccess.image"
                                                onerror="this.src='assets/company-avatar.png'" alt="" height="32px"
                                                class="avatar-image small contain">
                                            <div class="text-sm font-bold overflow-wrap-anywhere">
                                                {{companyAccess?.name}}
                                            </div>
                                        </div>
                                        <span class="access-status flex-shrink-0"
                                            *ngIf="companyAccess.accesses.length===1" [ngClass]="{
                                        active:companyAccess.accesses[0].status === UserAccessStatusEnum.ACTIVE,
                                        pending:companyAccess.accesses[0].status !== UserAccessStatusEnum.ACTIVE,
                                        'admin-unverified':companyAccess.accesses[0]?.role?.name === 'AdminUnverified',
                                    }"></span>
                                    </div>
                                    <div class="d-grid gap-4">
                                        <ng-container *ngFor="let access of companyAccess.accesses">
                                            <div *ngIf="access.location"
                                                class="text-sm font-regular overflow-wrap-anywhere margin-left-44"
                                                (click)="changeSelectedCompany(companyAccess,access)">
                                                {{access.location.name}}
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex j-center">
                                <albi-button [label]="appTopbarDictionary.changeCompany.button | translate"
                                    buttonVariant="textOnly" iconName="arrow.plus"
                                    (onClick)="navigateToOnboardingConnect()"></albi-button>
                            </div>
                        </ng-container>
                        <ng-template #unchangeableCompanyTemplate>
                            <p class="title">{{appTopbarDictionary.unchangeableCompany.title | translate}}</p>
                            <p style="max-width: 240px;">{{appTopbarDictionary.changeCompany.description | translate}}
                            </p>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>