
import { ICompany } from '@albi-types/base/company';
import IUser, { UserAccessStatusEnum, UserSession } from '@albi-types/base/user';
import { AfterViewInit, ApplicationRef, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl } from '@angular/forms';
import { Router, Scroll } from "@angular/router";
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AlbiOverlayPanel, TabSectionType } from 'albi-ui/dist/albi-ui-library';
import { BehaviorSubject, Observable, combineLatest, filter, map, startWith, tap } from 'rxjs';
import { AUTHENTICATION_SELECTORS } from 'src/authentication/store/authentication.selectors';
import { AuthService } from 'src/services/auth.service';
import { BackendService } from 'src/services/backend.service';
import { HeaderMessageService } from 'src/services/header-message.service';
import { SHARED_DICTIONARY } from 'src/translations/dictionaries/shared.dictionary';
import { USER_ACCESS_PAGE_ACTIONS } from 'src/userAccessStore/userAccessStore.actions';
import { USER_ACCES_SELECTORS } from 'src/userAccessStore/userAccessStore.selectors';
import { MAIN_BAR_SUGGESTIONS, RELEASE_NUMBER, ResolvedUserAccessType } from 'src/utils/sharedValues';
import { calculateCanChangeUserAccessFromUrl } from 'src/utils/utilities';
//import { UserSession['companyAccesses'] } from './store/dashboard.state';

@Component({
    selector: 'dashboard-component',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DashboardComponent implements OnInit, AfterViewInit {
    private readonly _destroy: DestroyRef = inject(DestroyRef);
    public theme = new BehaviorSubject<'dark' | 'light'>('light');

    public user$ = new Observable<IUser>;
    public companyAccesses$ = new Observable<(ICompany & { accesses: UserSession['companyAccesses'] })[]>;
    public selectedCompanyAccess$ = new Observable<ResolvedUserAccessType>;
    public canChangeCompany$: Observable<boolean>;

    public showEnterpriseCompanyAccessPanel$: Observable<boolean>;

    public mainBarSuggestions$ = new BehaviorSubject<any[]>(null);

    searchBarControl = new FormControl<string | object>(undefined);

    RELEASE_NUMBER = RELEASE_NUMBER;

    exampleTabs: TabSectionType[];

    public readonly sharedDictionary = SHARED_DICTIONARY;

    constructor(
        private _router: Router,
        private _elementRef: ElementRef,
        private _authService: AuthService,
        private _appRef: ApplicationRef,
        private _store: Store,
        private _translateService: TranslateService,
        private _actions: Actions,
        private _backendService: BackendService,
        private _headerMessageService: HeaderMessageService
    ) { }

    ngOnInit(): void {
        console.log('dashboard component')
        combineLatest([
            this._translateService.onLangChange.pipe(
                startWith(this._translateService.currentLang),
            ),
            this._store.select(USER_ACCES_SELECTORS.selectSelectedUserAccesses)
        ]).pipe(
            tap(([lang, selectedAccess]) => {
                this.exampleTabs = [
                    {
                        items: [
                            {
                                name: this._translateService.instant(this.sharedDictionary.dashboardTabs.home),
                                iconName: 'menu.home',
                                navigationUrl: 'dashboard',
                            },
                            {
                                name: this._translateService.instant(this.sharedDictionary.dashboardTabs.elabel),
                                iconName: 'stats.statsLabel',
                                navigationUrl: 'dashboard/elabel',
                                disabled: (!['Admin', 'WineManager'].includes(selectedAccess.role.name) || !(selectedAccess.company.type === 'winery'))
                            },
                            {
                                name: this._translateService.instant(this.sharedDictionary.dashboardTabs.wines),
                                iconName: 'wine.grapeVariety',
                                navigationUrl: 'dashboard/wines',
                                disabled: (!['Admin', 'WineManager'].includes(selectedAccess.role.name) || !(selectedAccess.company.type === 'winery'))
                            },
                            {
                                name: this._translateService.instant(this.sharedDictionary.dashboardTabs.dispenser),
                                iconName: 'dispenser',
                                navigationUrl: 'dashboard/dispenser',
                                disabled: (!['Admin', 'CantinaManager'].includes(selectedAccess.role.name))
                            },
                            {
                                name: this._translateService.instant(this.sharedDictionary.dashboardTabs.sellerPanel),
                                iconName: 'chassisConnected',
                                navigationUrl: 'dashboard/seller-panel',
                                disabled: true,//(!['Admin', 'StockManager'].includes(selectedAccess.role.name))
                            },
                            {
                                name: 'Cantina',
                                iconName: 'wine.winery',
                                navigationUrl: 'dashboard/cantina',
                                disabled: false,//(!['Admin', 'StockManager'].includes(selectedAccess.role.name))
                            },
                            {
                                name: 'Wine List',
                                iconName: 'stats.statsWines',
                                navigationUrl: 'dashboard/wine-list',
                                disabled: false,//(!['Admin', 'StockManager'].includes(selectedAccess.role.name))
                            },
                        ]
                    }, {
                        isBottomSection: true,
                        items: [
                            {
                                name: this._translateService.instant(this.sharedDictionary.dashboardTabs.company),
                                iconName: 'legal.businessAccount',
                                navigationUrl: 'dashboard/company',
                                disabled: (!['Admin'].includes(selectedAccess.role.name))
                            },
                            {
                                name: this._translateService.instant(this.sharedDictionary.dashboardTabs.profile),
                                iconName: 'menu.profile',
                                navigationUrl: 'profile/info',
                            }
                        ]
                    }
                ];
            }),
            takeUntilDestroyed(this._destroy)
        ).subscribe();

        this.user$ = this._store.select(AUTHENTICATION_SELECTORS.selectLoggedUser);//(this._backendService.get<{ user: IUser }>('users/me')).pipe(map(response => response.user));

        this.showEnterpriseCompanyAccessPanel$ = this._store.select(USER_ACCES_SELECTORS.selectShowEnterpriseAccessPanel);
        this.canChangeCompany$ = this._store.select(USER_ACCES_SELECTORS.selectCanChangeUserAcces);


        this.selectedCompanyAccess$ = this._store.select(USER_ACCES_SELECTORS.selectSelectedUserAccesses).pipe(
            tap(value => {

                //Wait for the store to be done with the user access state init
                //once done check if the company access is undefined go to compoany-connect
                //else if the company access is pending go to pendig document verification page
                //else do nothing so stay here
                this._store.select(USER_ACCES_SELECTORS.selectInitStoreDone).pipe(
                    filter(bool => bool),
                    tap(status => {
                        if (!value) {
                            this._router.navigate(['company-connect']);
                        } else if (
                            (!value.role) &&
                            (value.status === UserAccessStatusEnum.PENDING ||
                                value.status === UserAccessStatusEnum.PENDING_DOCUMENT ||
                                value.status === UserAccessStatusEnum.PENDING_EMAIL)
                        ) {
                            this._router.navigate(['company-pending-verification']);
                        }
                    }),
                    takeUntilDestroyed(this._destroy)
                ).subscribe();
            })
        );
        this.companyAccesses$ = this._store.select(USER_ACCES_SELECTORS.selectUserAccesses).pipe(
            map(userAccesses =>
                userAccesses?.reduce((acc: (ICompany & { accesses: UserSession['companyAccesses'] })[], curr) => {
                    const companyIndex = acc.findIndex(elm => elm._id === curr.company._id);
                    if (companyIndex === -1) {
                        acc.push({
                            ...curr.company,
                            accesses: [curr],
                        });
                    }
                    else {
                        acc[companyIndex].accesses.push(curr);
                    }
                    return acc;
                }, [])
            )
        );

        this._router.events.pipe(
            filter(event => event instanceof Scroll),
            tap(value => {
                const canChangeUserAccess = calculateCanChangeUserAccessFromUrl((value as Scroll).routerEvent.url);
                this._store.dispatch(USER_ACCESS_PAGE_ACTIONS.setCanChangeUserAcces({ value: canChangeUserAccess }));
            }),
            takeUntilDestroyed(this._destroy)
        ).subscribe();

        /*  if (this._router.routerState.snapshot.url === '/dashboard') {
              this._router.navigate(['dashboard/elabel'])
          }*/


        this.theme.pipe(
            tap(value => {
                const rootElement: HTMLElement = this._appRef.components[0].location.nativeElement;
                if (value === 'dark') {
                    rootElement.classList.add('dark-mode');
                    rootElement.classList.remove('light-mode');
                } else {
                    rootElement.classList.add('light-mode');
                    rootElement.classList.remove('dark-mode');
                }
            }),
            takeUntilDestroyed(this._destroy),
        ).subscribe();
    }


    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('app-component');
        // this._headerMessageService.setMessagePanel(document.getElementById('dashboard-message-panel'));
    }

    onChangeTheme(ev: 'dark' | 'light') {
        this.theme.next(ev);
        document.getElementsByTagName('body')[0].style.colorScheme = ev;
    }
    public logout() {
        this._authService.logout();
    }

    changeSelectedCompany(op: AlbiOverlayPanel, companyGroupedAccesses: ICompany & { accesses: UserSession['companyAccesses'] }, access?: ResolvedUserAccessType) {
        op.hide();
        const selectedAccess = access || companyGroupedAccesses.accesses.find(elm => elm.location == undefined) || companyGroupedAccesses.accesses[0];
        this._store.dispatch(USER_ACCESS_PAGE_ACTIONS.changeSelectedUserAcces({ companyAccess: selectedAccess }));
    }

    redirectToUserPage() {
        // window.open(`profile/info`, "_blank");
        this._router.navigate(['profile/info']);
    }

    completeMainBarSearch(ev: string) {
        if (ev) {
            const suggestions = MAIN_BAR_SUGGESTIONS.filter(elm => elm.tags.some(tag => tag.includes(ev.toLowerCase())));
            suggestions.length > 0 ? this.mainBarSuggestions$.next(suggestions) : this.mainBarSuggestions$.next(null);
        } else {
            this.mainBarSuggestions$.next(null);
        }
    }

    mainSuggestionClick(suggestion: { path: string, name: string, tags: string[] }) {
        this.searchBarControl.setValue(undefined);
        this._router.navigate([suggestion.path]);
    }
}