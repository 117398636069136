<ng-container *ngIf="{
    bottleImage:bottleImage$ | async,
    labelImage:labelImage$ | async,
    winePrimaryPairingsSuggestions:winePrimaryPairingsSuggestions$ | async,
    wineSecondaryPairingsSuggestions: wineSecondaryPairingsSuggestions$ | async,
    wineMoodsSuggestions: wineMoodsSuggestions$ | async,
    wineAllergensSuggestions:wineAllergensSuggestions$ | async,
    winePhilosophySuggestions: winePhilosophySuggestions$ | async,
    showVintageYearPicker:showVintageYearPicker$ | async,
    isYearDetailRequired:isYearDetailRequired$ | async,

    loadedLabelImageFile:loadedLabelImageFile$ | async,
    loadedBottleImageFile:loadedBottleImageFile$ | async,
} as vm">

    <div class="border-card">
        <div class="form-group">
            <div class="form-input-wrapper col-span-2 d-grid gap-12">
                <div class="text-lg font-bold">{{vintageFormDictionary.title | translate}}</div>
                <p class="text-sm font-regular">{{vintageFormDictionary.description | translate}}</p>
            </div>

            <div class="divider col-span-2"></div>

            <div class="form-input-wrapper">
                <div class="d-grid gap-12">
                    <div class="form-input-label">
                        <div class="text-sm font-bold">{{vintageFormDictionary.year.title | translate}}</div>
                        <p class="text-sm font-regular">{{vintageFormDictionary.year.description | translate}}</p>
                    </div>
                    <div class="d-grid gap-12">
                        <albi-radio-button [values]="vintageTypeOptions" radioGroup="vintageType" [isFluid]="true"
                            [formControl]="vintageCreateForm.controls.yearType"></albi-radio-button>
                        <albi-calendar *ngIf="vm.showVintageYearPicker" [formControl]="vintageCreateForm.controls.year"
                            variant="yearPicker"
                            [placeholder]="vintageFormDictionary.year.vintageOption.vintagePlaceholder | translate"
                            [fullWidth]="true"></albi-calendar>
                        <p *ngIf="vm.isYearDetailRequired" class="required-control-tex">Required *</p>
                        <albi-input *ngIf="vintageCreateForm.controls.yearType.value"
                            [control]="vintageCreateForm.controls.yearDetails" [fullWidth]="true"
                            [placeholder]="vintageFormDictionary.year.yearDetailPlaceholder | translate"></albi-input>
                    </div>
                </div>
            </div>
            <!--  Images -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">{{vintageFormDictionary.images.title | translate}}</div>
                    <p class="text-sm font-regular">{{vintageFormDictionary.images.description | translate}}</p>
                </div>
                <div class="image-load-container">
                    <div class="image-wrapper" [ngClass]="{
                            'image-size-error':vintageCreateForm.controls.labelImage.value?.sizeError
                        }">
                        <div class="img-title">{{vintageFormDictionary.images.labelImage.title| translate}}</div>
                        <div class="text-xs font-regular">{{vintageFormDictionary.images.labelImage.description |
                            translate}}</div>
                        <div class="relative">
                            <img [src]="vm.labelImage" onerror="this.src='assets/label-placeholder.png'" alt="">
                            <albi-icon *ngIf="vm.labelImage" class="remove-img-icon" name="arrow.close"
                                (onClick)="removeImage('label')"></albi-icon>
                        </div>
                        <albi-button
                            [label]="vm.labelImage?(sharedDictionary.button.change | translate):(sharedDictionary.button.add | translate)"
                            [iconName]="vm.labelImage?'arrow.repeat':'arrow.plus'" buttonVariant="textOnly"
                            (onClick)="handleAddLabel()"></albi-button>
                        <span class="text-danger"
                            *ngIf="vintageCreateForm.controls.labelImage.value?.sizeError">{{vintageFormDictionary.images.labelImage.description
                            | translate}}</span>
                    </div>
                    <div class="image-wrapper" [ngClass]="{
                        'image-size-error':vintageCreateForm.controls.bottleImage.value?.sizeError
                    }">
                        <div class="img-title">{{vintageFormDictionary.images.bottleImage.title | translate}}</div>
                        <div class="text-xs font-regular">{{vintageFormDictionary.images.bottleImage.description |
                            translate}}</div>
                        <div class="relative">
                            <img [src]="vm.bottleImage" onerror="this.src='assets/bottle-placeholder.png'" alt="">
                            <albi-icon *ngIf="vm.bottleImage" class="remove-img-icon" name="arrow.close"
                                (onClick)="removeImage('bottle')"></albi-icon>
                        </div>
                        <albi-button
                            [label]="vm.bottleImage?(sharedDictionary.button.change | translate):(sharedDictionary.button.add | translate)"
                            [iconName]="vm.bottleImage?'arrow.repeat':'arrow.plus'" buttonVariant="textOnly"
                            (onClick)="handleAddBottle()"></albi-button>
                        <span class="text-danger"
                            *ngIf="vintageCreateForm.controls.bottleImage.value?.sizeError">{{vintageFormDictionary.images.bottleImage.description
                            | translate}}</span>
                    </div>
                </div>
            </div>


            <!-- description -->
            <div class="form-input-wrapper col-span-2">
                <div class="d-flex j-between align-center p-24 gap-3">
                    <div class="form-input-label">
                        <div class="text-sm font-bold">{{vintageFormDictionary.descriptionInput.title | translate}}
                        </div>
                        <p>{{vintageFormDictionary.descriptionInput.description | translate}}</p>
                    </div>
                    <div style="width:20rem">
                        <albi-dropdown class="text-sm font-regular"
                            [placeholder]="vintageFormDictionary.descriptionInput.langPlaceholder | translate"
                            [options]="languageOptions"
                            [formControl]="vintageCreateForm.controls.descriptionLanguage"></albi-dropdown>
                    </div>
                </div>
                <albi-textarea [control]="vintageCreateForm.controls.descriptionText"></albi-textarea>
            </div>

            <!-- acohol -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">{{vintageFormDictionary.alcohol.title | translate}}</div>
                    <p class="text-sm font-regular">{{vintageFormDictionary.alcohol.description | translate}}</p>
                </div>
                <albi-input [control]="vintageCreateForm.controls.alcohol" [fullWidth]="true" type="number"
                    [minValue]="0" [maxValue]="100"></albi-input>

            </div>

            <!-- service temperature -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">{{vintageFormDictionary.temperature.title | translate}}</div>
                    <p class="text-sm font-regular">{{vintageFormDictionary.temperature.description | translate}}</p>
                </div>
                <albi-input [control]="vintageCreateForm.controls.temperature" [fullWidth]="true" type="number"
                    [minValue]="0" [maxValue]="30"></albi-input>
            </div>

            <!-- pairings perfect -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">{{vintageFormDictionary.primaryPairings.title | translate}}</div>
                    <p class="text-sm font-regular">{{vintageFormDictionary.primaryPairings.description | translate}}
                    </p>
                </div>
                <albi-chips [formControl]="vintageCreateForm.controls.primaryFoods"
                    (completeMethod)="completePairingsSuggestions($event,'primary')"
                    [suggestions]="vm.winePrimaryPairingsSuggestions" [forceSelection]="true" [dropdownButton]="true"
                    (onDropdownClick)="completePairingsSuggestions(vm.winePrimaryPairingsSuggestions?.length? '':$event,'primary',vm.winePrimaryPairingsSuggestions?.length?false: true)"
                    [maxNumberOfChips]="3"></albi-chips>
            </div>

            <!-- pairings good -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">{{vintageFormDictionary.secondaryPairings.title | translate}}</div>
                    <p>{{vintageFormDictionary.secondaryPairings.description | translate}}</p>
                </div>
                <albi-chips [formControl]="vintageCreateForm.controls.secondaryFoods"
                    (completeMethod)="completePairingsSuggestions($event,'secondary')"
                    [suggestions]="vm.wineSecondaryPairingsSuggestions" [forceSelection]="true" [dropdownButton]="true"
                    (onDropdownClick)="completePairingsSuggestions(vm.wineSecondaryPairingsSuggestions?.length? '':$event,'secondary',vm.wineSecondaryPairingsSuggestions?.length?false: true)"
                    [maxNumberOfChips]="6"></albi-chips>
            </div>

            <div class="divider col-span-2"></div>

            <!-- mood -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">{{vintageFormDictionary.mood.title | translate}}</div>
                    <div class="text-sm font-regular">{{vintageFormDictionary.mood.description | translate}}</div>
                </div>
                <albi-chips class="text-sm font-regular" [formControl]="vintageCreateForm.controls.moods"
                    (completeMethod)="completeMoodsSuggestions($event)" [suggestions]="vm.wineMoodsSuggestions"
                    [forceSelection]="true" [dropdownButton]="true"
                    (onDropdownClick)="completeMoodsSuggestions(vm.wineMoodsSuggestions?.length? '':$event,vm.wineMoodsSuggestions?.length?false: true)"></albi-chips>
            </div>

            <!-- philosophy -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">{{vintageFormDictionary.philosophy.title | translate}}</div>
                    <div class="text-sm font-regular">{{vintageFormDictionary.philosophy.description | translate}}</div>
                </div>
                <albi-chips class="text-sm font-regular" [formControl]="vintageCreateForm.controls.philosophy"
                    (completeMethod)="completePhilosophySuggestions($event)"
                    [suggestions]="vm.winePhilosophySuggestions" [forceSelection]="true" [dropdownButton]="true"
                    (onDropdownClick)="completePhilosophySuggestions(vm.winePhilosophySuggestions?.length? '':$event,vm.winePhilosophySuggestions?.length?false: true)"></albi-chips>
            </div>

            <!-- allergens -->
            <div class="form-input-wrapper">
                <div class="form-input-label">
                    <div class="text-sm font-bold">{{vintageFormDictionary.allergen.title | translate}}</div>
                    <div class="text-sm font-regular">{{vintageFormDictionary.allergen.description | translate}}</div>
                </div>
                <albi-chips class="text-sm font-regular" [formControl]="vintageCreateForm.controls.allergens"
                    (completeMethod)="completeAllergensSuggestions($event)" [suggestions]="vm.wineAllergensSuggestions"
                    [forceSelection]="true" [dropdownButton]="true"
                    (onDropdownClick)="completeAllergensSuggestions(vm.wineAllergensSuggestions?.length? '':$event,vm.wineAllergensSuggestions?.length?false: true)"></albi-chips>
            </div>

            <!-- awards -->
            <div class="form-input-wrapper col-span-2">
                <div class="form-input-label">
                    <div class="text-sm font-bold">{{vintageFormDictionary.awards.title | translate}}</div>
                    <p class="text-sm font-regular">{{vintageFormDictionary.awards.description | translate}}</p>
                </div>
                <albi-chips class="text-sm font-regular" textIcon="🏆" class="h-full"
                    [formControl]="vintageCreateForm.controls.awards"></albi-chips>
            </div>
        </div>
    </div>

    <!--  ERROR label size -->
    <div class="crop-image-dialog">
        <albi-dialog [header]="vintageFormDictionary.cropLabelPanel.title | translate"
            [visible]="Boolean(vm.loadedLabelImageFile)" (visibleChange)="removeLoadedLabelFile()">
            <div class="d-flex flex-column">
                <div class="text-sm font-regular">{{vintageFormDictionary.cropLabelPanel.description | translate}}</div>
                <app-image-crop class="flex-1" [height]="640" [width]="480" [imageFile]="vm.loadedLabelImageFile"
                    (imageResult)="updateLabelImageResult($event)"></app-image-crop>
            </div>
            <ng-template #footerTemplate>
                <div class="d-flex j-end gap-24">
                    <albi-button [label]="sharedDictionary.button.cancel | translate" buttonVariant="outlined"
                        (onClick)="removeLoadedLabelFile()"></albi-button>
                    <albi-button [label]="sharedDictionary.button.apply | translate"
                        (onClick)="applyLabelCrop()"></albi-button>
                </div>
            </ng-template>
        </albi-dialog>

    </div>
    <!--  ERROR bottle size -->
    <div class="crop-image-dialog">
        <albi-dialog [header]="vintageFormDictionary.cropBottlePanel.title | translate"
            [visible]="Boolean(vm.loadedBottleImageFile)" (visibleChange)="removeLoadedBottleFile()">
            <div class="d-flex flex-column">
                <div class="text-sm font-regular">{{vintageFormDictionary.cropBottlePanel.description | translate}}
                </div>
                <app-image-crop class="flex-1" [height]="960" [imageFile]="vm.loadedBottleImageFile"
                    (imageResult)="updateBottleImageResult($event)"></app-image-crop>
            </div>
            <ng-template #footerTemplate>
                <div class="d-flex j-end gap-24">
                    <albi-button [label]="sharedDictionary.button.cancel | translate" buttonVariant="outlined"
                        (onClick)="removeLoadedBottleFile()"></albi-button>
                    <albi-button [label]="sharedDictionary.button.apply | translate"
                        (onClick)="applyBottleCrop()"></albi-button>
                </div>
            </ng-template>
        </albi-dialog>

    </div>
</ng-container>