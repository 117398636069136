import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appTooltip]',
    standalone: false
})
export class TooltipDirective {
    @Input('tooltipText') tooltipText: string = '';
    tooltip: HTMLElement | null = null;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    // Show the tooltip on mouse enter
    @HostListener('mouseenter') onMouseEnter() {
        if (!this.tooltip) {
            this.showTooltip();
        }
    }

    // Hide the tooltip on mouse leave
    @HostListener('mouseleave') onMouseLeave() {
        if (this.tooltip) {
            this.hideTooltip();
        }
    }

    // Show tooltip method
    private showTooltip() {
        this.tooltip = this.renderer.createElement('span');
        this.tooltip.innerText = this.tooltipText;

        this.renderer.appendChild(document.body, this.tooltip);
        this.renderer.addClass(this.tooltip, 'tooltip');

        const hostPos = this.el.nativeElement.getBoundingClientRect();
        const tooltipPos = this.tooltip.getBoundingClientRect();

        const top = hostPos.top - tooltipPos.height - 10; // 10px above the element
        let left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;

        if (left < 0) {
            left = 8;
        }

        // Set tooltip styles
        this.renderer.setStyle(this.tooltip, 'position', 'absolute');
        this.renderer.setStyle(this.tooltip, 'top', `${top}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
        this.renderer.setStyle(this.tooltip, 'background-color', '#333');
        this.renderer.setStyle(this.tooltip, 'color', '#fff');
        this.renderer.setStyle(this.tooltip, 'padding', '5px 10px');
        this.renderer.setStyle(this.tooltip, 'border-radius', '4px');
        this.renderer.setStyle(this.tooltip, 'font-size', '12px');
        this.renderer.setStyle(this.tooltip, 'white-space', 'normal');
        this.renderer.setStyle(this.tooltip, 'z-index', '1000');
        this.renderer.setStyle(this.tooltip, 'max-width', '80dvw');
        this.renderer.setStyle(this.tooltip, 'break-word', '1000');
        this.renderer.setStyle(this.tooltip, 'overflow-wrap', 'anywhere');
        this.renderer.setStyle(this.tooltip, 'transition', 'opacity 0.2s ease-in-out');


        // Add a class for fade-in effect
        setTimeout(() => {
            this.renderer.addClass(this.tooltip, 'tooltip-show');
        });
    }

    // Hide tooltip method
    private hideTooltip() {
        if (this.tooltip) {
            this.renderer.removeClass(this.tooltip, 'tooltip-show');
            setTimeout(() => {
                if (this.tooltip) {
                    this.renderer.removeChild(document.body, this.tooltip);
                    this.tooltip = null;
                }
            }, 200); // Match the fade-out transition
        }
    }
}
